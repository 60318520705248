import { gql } from '@apollo/client';

/**
 * Query returns the following:
 * null: customerReference is unique
 * shippio: customerReference is not unique, blocked by shippio shipment
 * non-shippio: customerReference is not unique, blocked by non-shippio shipment
 */

export default gql`
  query CustomerReferenceUniquenessSearch (
    $customerReference: String!
    $customerTeamId: ID!
    $currentShipmentId: ID!
    ) {
      customerReferenceUniquenessSearch(
        customerReference: $customerReference
        customerTeamId: $customerTeamId
        currentShipmentId: $currentShipmentId
      )
  }
`;
