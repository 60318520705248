import React, { useMemo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';

import { createClient } from '../config/apolloClient';
import { removeCookieToken, storeCookieToken } from '../config/cookies';
import { LOCAL_STORAGE_REMOVABLE_KEYS, USER_TYPE } from '../utils/constants/auth';
import { SIGN_OFF } from '../graphql/mutations';

const AuthTokenContext = React.createContext();

const propTypes = {
  children: PropTypes.node.isRequired,
};

const AuthTokenProvider = ({ children }) => {
  // eslint-disable-next-line no-unused-vars
  const [client, setClient] = useState(() => createClient(null));

  /**
   * add token and use to create client authorized to connect to subscriptions
   * @param {string} token auth token
   */
  const createAuthorizedClient = (token) => {
    const nextClient = createClient(token);

    if (token) {
      storeCookieToken(token, USER_TYPE);
    }

    nextClient.resetStore().catch((e) => (
      // eslint-disable-next-line no-console
      console.log('%cError on cache reset (login)', 'color: orange;', e.message)
    )).then(() => setClient(nextClient));
  };

  /**
   * remove token on, dispose of old client, and create new client without
   * authorization to connect to subscriptions
   */
  const disposeAuthorizedClient = () => {
    client.mutate({ mutation: SIGN_OFF }).then(() => {
      client.clearStore();
      client.stop();

      removeCookieToken();

      LOCAL_STORAGE_REMOVABLE_KEYS.forEach((key) => localStorage.removeItem(key));

      window.location.href = '/';

      const nextClient = createClient(null);
      setClient(nextClient);
    });
  };

  // Client provided via this context only for the class component Login,
  // which cannot access it via the `useApolloClient` hook.
  // Function components should use the `useApolloClient` hook.
  const value = useMemo(() => ({
    createAuthorizedClient,
    disposeAuthorizedClient,
    client,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [client]);

  return (
    <AuthTokenContext.Provider value={value}>
      <ApolloProvider client={client}>
        {children}
      </ApolloProvider>
    </AuthTokenContext.Provider>
  );
};

AuthTokenProvider.propTypes = propTypes;

/**
 * Hook that provides functions to create and dispose of client
 * @note the `client` returned is intended for use only when the ApolloProvider's
 * context is not available, such as in class components.
 * @returns {
 *  createAuthorizedClient: (token: string) => void;
 *  disposeAuthorizedClient: () => void;
 *  client: ApolloClient;
 * }
 */
const useAuthTokenContext = () => useContext(AuthTokenContext);

export {
  AuthTokenProvider,
  useAuthTokenContext,
  AuthTokenContext,
};
