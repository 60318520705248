import { gql } from '@apollo/client';

export default gql`
  query PricingSectionSearch($customerTeamId: ID!, $tradeDirection: TradeDirectionEnum, $pricingSectionId: ID){
    pricingSectionSearch(customerTeamId: $customerTeamId, tradeDirection: $tradeDirection, pricingSectionId: $pricingSectionId) {
      id
      withCarrier
      carrier {
        id
        name
      }
      criteria {
        pol {
          id
          unlocode
          portType
        }
        pod {
          id
          unlocode
          portType
        }
        tradeDirection
        transships
        address {
          id
          name
        }
      }
      meta {
        cargoType
        dem
        det
        frequency
        transitTime
        voyageNumber
      }
      name
      pricingSectionType
      status
      expirationDate
      pricingItems {
        id
        criteria {
          containerType
          drayageChassisType
        }
        status
        pricingItemType
        description
      }
      partner {
        id
        name
      }
    }
  }
`;
