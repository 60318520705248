import { gql } from '@apollo/client';

export default gql`
  mutation CreateCustomerTeam(
    $companyId: ID!
    $customerTeamBasicInfoInput: CustomerTeamDataInput!
    $customerTeamTradeInfoInput: TradeInfoInput
    $customerTeamPaymentInfoInput: PaymentInfoInput
    $customerTeamsBillSettings: [CustomerTeamsBillSettingInput!]
  ) {
    createCustomerTeam(
      companyId: $companyId
      customerTeamDataInput: $customerTeamBasicInfoInput
      tradeInfoInput: $customerTeamTradeInfoInput
      paymentInfoInput: $customerTeamPaymentInfoInput
      customerTeamsBillSettings: $customerTeamsBillSettings
    ) {
      id
      name
    }
  }
`;
