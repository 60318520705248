import { gql } from '@apollo/client';

export default gql`
  mutation CreateMemoList(
    $memoListContexts: [MemoListContextInput!]!
    $customerTeamId: ID!
    $content: String
  ) {
    createMemoList(
      memoListContexts: $memoListContexts
      customerTeamId: $customerTeamId
      content: $content
    ) {
      id
    }
  }
`;
