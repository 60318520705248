import { gql } from '@apollo/client';

export default gql`
  mutation updateQuoteRequest(
    $quoteRequestId: ID!
    $status: QuoteRequestStatusEnum
    $details: QuoteRequestUpdateInput
  ) {
    updateQuoteRequest(
      quoteRequestId: $quoteRequestId
      status: $status
      details: $details
    ) {
      id
    }
  }
`;
