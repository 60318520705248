import { gql } from '@apollo/client';

export default gql`
  mutation UpsertPricingSection($pricingSectionId: ID, $customerTeamId: ID, $carrierId: ID, $partnerId: ID, $pricingSectionInput: PricingSectionInput, $criteriaInput: PricingSectionCriteriaInput, $metaInput: MetaInput) {
    upsertPricingSection(pricingSectionId: $pricingSectionId, customerTeamId: $customerTeamId, carrierId: $carrierId, partnerId: $partnerId, pricingSectionInput: $pricingSectionInput, criteriaInput: $criteriaInput, metaInput: $metaInput) {
      id
      withCarrier
      carrier {
        id
        name
      }
      criteria {
        pol {
          id
          unlocode
        }
        pod {
          id
          unlocode
        }
        tradeDirection
        transships
      }
      meta {
        cargoType
        dem
        det
        frequency
        transitTime
        voyageNumber
      }
      name
      pricingSectionType
      status
      pricingItems {
        id
      }
      expirationDate
      partner {
        id
        name
      }
    }
  }
`;
