import { gql } from '@apollo/client';

export default gql`
  query retrieveGlobalSetting {
    retrieveGlobalSetting {
      exchangeRates {
        JPY {
          JPY
          USD
        }
        USD {
          JPY
          USD
        }
      }
      updatedAt
    }
  }
`;
