import { gql } from '@apollo/client';

export default gql`
mutation CreateIdentifiedCustomerPartner(
  $icpArgs: IdentifiedCustomerPartnerInput!
) {
  createIdentifiedCustomerPartner(icpArgs: $icpArgs) {
    id
    companyName
    partnerType
  }
}
`;
