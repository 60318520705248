import { gql } from '@apollo/client';

export default gql`
  query IdentifiedCustomerPartnerSearchPagination(
    $icpId: ID
    $pagination: PaginationInput
  ) {
    identifiedCustomerPartnerSearchPagination(
      icpId: $icpId
      pagination: $pagination
    ) {
      totalCount
    }
  }
`;
