import { gql } from '@apollo/client';

export default gql`
  query GetMemoLists(
    $shipmentId: ID
  ) {
    backOfficeShipmentSearch(
      shipmentId: $shipmentId
    ) {
      memoLists {
        id
        memos {
          content
          id
        }
      }
    }
  }
`;
