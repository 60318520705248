import { gql } from '@apollo/client';

export default gql`
  query AddressSearchPagination(
    $customerTeamId: ID!
    $addressId: ID
    $addressType: AddressTypeEnum
    $pagination: PaginationInput
  ) {
    addressSearchPagination(
      customerTeamId: $customerTeamId
      addressId: $addressId
      addressType: $addressType
      pagination: $pagination
    ) {
      totalCount
    }
  }
`;
