import { gql } from '@apollo/client';

export default gql`
  mutation RequestDirectUpload($filename: String!) {
    requestDirectUpload(filename: $filename) {
      url
      serializedFields
    }
  }
`;
