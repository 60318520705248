import { gql } from '@apollo/client';

export default gql`
  query GetUserGroups($customerTeamId: ID) {
    customerTeamSearch(customerTeamId: $customerTeamId) {
      id
      company {
        id
        name
      }
      name
      userGroups {
        id
        name
      }
    }
  }
`;
