import { gql } from '@apollo/client';

export default gql`
  query GetCustomerUserTeamById($customerTeamId: ID){
    customerTeamSearch(customerTeamId: $customerTeamId) {
      id
      name
      settings {
        importQuote {
          incoterm,
          containers,
          drayageChassisTypes,
          truckTypes,
        }
        exportQuote {
          incoterm,
          containers,
          drayageChassisTypes,
          truckTypes,
        }
      }
    }
  }
`;
