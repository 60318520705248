import { gql } from '@apollo/client';

export default gql`
mutation unlinkIcp(
  $icpId: ID!
  $tradePartnerId: ID!
) {
  unlinkIcp(
    icpId: $icpId
    tradePartnerId: $tradePartnerId
  ) {
    id
  }
}
`;
