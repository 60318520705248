import { gql } from '@apollo/client';

export default gql`
  query CustomerPartnerUserSearch($icpId: ID!, $pagination: PaginationInput){
    customerPartnerUserSearch(icpId: $icpId, pagination: $pagination) {
      email
      firstName
      id
      lastName
      identifiedCustomerPartner {
        companyName
        customerPartnerId: id
        partnerType
      }
      threeWayFunctionDisabled
    }
  }
`;
