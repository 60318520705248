import { gql } from '@apollo/client';

export default gql`
  query ChannelSearchPagination(
    $channelId: ID
    $companyId: ID
    $customerTeamId: ID
    $channelTypeFilter: ChannelTypeFilterEnum = all
    $pagination: PaginationInput,
    ) {
      channelSearchPagination(
      channelId: $channelId,
      companyId: $companyId,
      customerTeamId: $customerTeamId,
      channelTypeFilter: $channelTypeFilter,
      pagination: $pagination,
      ) {
        totalCount
    }
  }
`;
