import { gql } from '@apollo/client';

export default gql`
  mutation updateMilestone(
    $milestoneId: ID!
    $milestoneInput: MilestoneInput!
  ) {
    updateMilestone(
      milestoneId: $milestoneId
      milestoneInput: $milestoneInput
    ) {
      id
    }
  }
`;
