import { gql } from '@apollo/client';

export default gql`
  query GetUserGroupsPagination(
    $customerTeamId: ID
    $pagination: PaginationInput
  ) {
    customerTeamSearchPagination(
      customerTeamId: $customerTeamId
      pagination: $pagination
    ) {
      totalCount
    }
  }
`;
