import { gql } from '@apollo/client';
import { SHIPMENT_BASIC } from '../../fragments/Shipment';

export default gql`
  query BackOfficeShipmentSearch(
    $shipmentId: ID
    $scopedSearchArgs: BackOfficeShipmentSearchInput
    $orderOption: BackOfficeOrderByEnum
    $pagination: PaginationInput
  ) {
    backOfficeShipmentSearch(
      shipmentId: $shipmentId
      scopedSearchArgs: $scopedSearchArgs
      orderOption: $orderOption
      pagination: $pagination
    ) {
      ...BasicFields
    }
  }
  ${SHIPMENT_BASIC}
`;
