import { gql } from '@apollo/client';

export default gql`
  mutation UpdateReadBy(
    $messageId: ID!) {
    updateReadBy(
      messageId: $messageId) {
      id
      unreadMessageCount
    }
  }
`;
