import { gql } from '@apollo/client';

export default gql`
  mutation CreateBill($shipmentId: ID, $sectionId: ID, $billInput: BillInput!) {
    createBill(
      shipmentId: $shipmentId
      sectionId: $sectionId
      billInput: $billInput
    ) {
      id
    }
  }
`;
