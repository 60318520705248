import { gql } from '@apollo/client';

export default gql`
  query IdentifiedCustomerPartnerSearch(
    $icpId: ID,
    $pagination: PaginationInput
  ) {
    identifiedCustomerPartnerSearch(
      icpId: $icpId,
      pagination: $pagination
    ) {
      addresses {
        id
      }
      companyName
      id
      partnerType
    }
  }
`;
