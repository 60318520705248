import { gql } from '@apollo/client';

export default gql`
  {
    currentUserResolver {
      id
      email
      name
    }
  }
`;
