import { gql } from '@apollo/client';
import { CHANNEL_MESSAGE_FIELDS } from '../fragments/Channel';

export default gql`
  query ChannelMessageSearch(
    $channelId: ID!
  ) {
    channelMessageSearch(
      channelId: $channelId
    ) {
      ...ChannelMessageFields
    }
  }
  ${CHANNEL_MESSAGE_FIELDS}
`;
