import { gql } from '@apollo/client';

export default gql`
  fragment ChannelFields on Shipment {
    channels {
      id
      channelContexts {
        contextType
        contextId
      }
      participantsType
      unreadMessageCount
    }
  }
`;
