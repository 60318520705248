import { gql } from '@apollo/client';

export default gql`
  {
    vesselMasterList {
      id
      carrier {
        id
        name
      }
      pod {
        id
        unlocode
      }
      pol {
        id
        unlocode
      }
      status
      transship
    }
  }
`;
