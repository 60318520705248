import { gql } from '@apollo/client';

export default gql`
  mutation UpsertPricingItem(
    $pricingSectionId: ID!
    $pricingItemId: ID
    $upsertArgs: PricingItemInput
    $criteriaArgs: PricingItemCriteriaInput
  ) {
    upsertPricingItem(
      pricingSectionId: $pricingSectionId
      pricingItemId: $pricingItemId
      upsertArgs: $upsertArgs
      criteriaArgs: $criteriaArgs
    ) {
      id
    }
  }
`;
