import { gql } from '@apollo/client';

export default gql`
  mutation upsertAddress(
    $customerTeamId: ID!, $addressId: ID, $tradePartnerId: ID, $addressInput: AddressInput!
  ) {
    upsertAddress(
      customerTeamId: $customerTeamId, addressId: $addressId, tradePartnerId: $tradePartnerId,
      addressInput: $addressInput
    ) {
      id
    }
  }
`;
