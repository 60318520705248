import { gql } from '@apollo/client';

export default gql`
  query AddressSearch(
    $customerTeamId: ID!
    $addressType: AddressTypeEnum
    $tradePartnerTypes: [TradePartnerTypeEnum!]
    $tradePartnerId: ID
    $search: String
    $addressId: ID
  ) {
    addressSearch(
      customerTeamId: $customerTeamId
      addressType: $addressType
      tradePartnerTypes: $tradePartnerTypes
      tradePartnerId: $tradePartnerId
      searchString: $search
      addressId: $addressId
    ) {
      id
      name
      countryCode
    }
  }
`;
