import { gql } from '@apollo/client';

export default gql`
  query VesselSearch($vesselId: ID, $searchString: String, $pagination: PaginationInput) {
    vesselSearch(vesselId: $vesselId, searchString: $searchString, pagination: $pagination) {
      id
      name
    }
  }
`;
