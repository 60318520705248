import { gql } from '@apollo/client';
/**
 * Based on this sheet:
 * https://docs.google.com/spreadsheets/d/1eYkcW7XOwQ5APySXpROVHz8lCpUccbgmbv000F8lWDo/edit#gid=0
 *
 * This following are excluded because they are already retrieved in basic request
 * -> customerReference
 */
export default gql`
  fragment SeldomChangingFields on Shipment {
    cargo {
      id
      cargoDescription
    }
  }
`;
