import { gql } from '@apollo/client';

export default gql`
  query QuoteLaneSearch(
    $quoteLaneId: ID!
  ){
    quoteLaneSearch(
      quoteLaneId: $quoteLaneId
      ) {
      id
      assignee {
        ...on CustomerUser {
          id
          firstName
          lastName
        }
        ...on UserGroup {
          id
          name
        }
      }
    }
  }
`;
