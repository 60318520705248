import { gql } from '@apollo/client';

export default gql`
  mutation upsertUserGroup(
    $userGroupId: ID,
    $customerTeamId: ID!,
    $userGroupInput: UserGroupInput!) {
  upsertUserGroup(
    userGroupId: $userGroupId,
    customerTeamId: $customerTeamId,
    userGroupInput: $userGroupInput) {
      id
      name
    }
  }
`;
