import { gql } from '@apollo/client';

export default gql`
  mutation SignIn(
    $loginInformations: LoginInformationInput!
  ) {
    signIn(
      loginInformations: $loginInformations
    ) {
      loginToken
    }
  }
`;
