import { gql } from '@apollo/client';

export default gql`
mutation DeleteCustomerTeamsBillSetting($customerTeamsBillSettingId: ID!) {
  deleteCustomerTeamsBillSetting(customerTeamsBillSettingId: $customerTeamsBillSettingId) {
    billingType
    contractFrom
    contractTo
    id
  }
}
`;
