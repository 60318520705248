import { gql } from '@apollo/client';

export default gql`
  mutation upsertTradePartner(
    $tradePartnerId: ID,
    $tradePartnerArgs: TradePartnerInput!,
    $customerTeamId: ID!) {
  upsertTradePartner(
    tradePartnerId: $tradePartnerId,
    tradePartnerArgs: $tradePartnerArgs,
    customerTeamId: $customerTeamId) {
      id
    }
  }
`;
