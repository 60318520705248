import { gql } from '@apollo/client';

export default gql`
  mutation assignFile($attachmentId: ID!, $s3Key: String) {
    assignFile(
      attachmentId: $attachmentId
      s3Key: $s3Key
    ) {
      id
      name
      url
    }
  }
`;
