import { gql } from '@apollo/client';

export default gql`
  mutation CreatePartnerContract(
    $customerTeamId: ID!
    $partnerId: ID!
  ) {
    createPartnerTeamContract(
      customerTeamId: $customerTeamId
      partnerId: $partnerId
    ) {
      id
    }
  }
`;
