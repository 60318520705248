import ThemeOptions from './ThemeOptions';
import quote from './quote/reducers';
import vesselSchedule from './vesselSchedule/reducers';
import dataManagement from './dataManagement/reducers';
import shipmentDetails from './shipmentDetails/reducers';
import user from './user/reducers';
import chat from './chat/reducers';

export default {
  ThemeOptions,
  dataManagement,
  quote,
  vesselSchedule,
  shipmentDetails,
  user,
  chat,
};
