import { gql } from '@apollo/client';

export default gql`
  mutation updateAttachment(
    $attachmentId: ID!,
    $attachmentArgs: AttachmentUpdateInput!
  ) {
    updateAttachment(
      attachmentId: $attachmentId,
      attachmentArgs: $attachmentArgs
    ) {
      id
    }
  }
`;
