import { gql } from '@apollo/client';

export default gql`
  query customerPartnerUserSearchPagination(
    $icpId: ID!
    $pagination: PaginationInput
  ) {
    customerPartnerUserSearchPagination(
      icpId: $icpId
      pagination: $pagination
    ) {
      totalCount
    }
  }
`;
