import { gql } from '@apollo/client';

export default gql`
  mutation UpsertMilestoneEvent(
    $milestoneEventId: ID
    $milestoneEventInput: MilestoneEventInput!
  ) {
    upsertMilestoneEvent(
      milestoneEventId: $milestoneEventId
      milestoneEventInput: $milestoneEventInput
    ) {
      id
    }
  }
`;
