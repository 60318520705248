import React, { Suspense, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Rollbar from 'rollbar';
import { SubscriptionEventProvider } from './context/SubscriptionEventContext';

import AppMain from './layout/AppMain';
import Loading from './layout/AppMain/Loading';
import ErrorBoundary from './components/ErrorBoundary';

const App = () => {
  useEffect(() => {
    window.rollbar = null;
    if (!window.rollbar) {
      // Rollbar config is here
      const rollbar = new Rollbar({
        accessToken: process.env.REACT_APP_ROLLBAR_POST_CLIENT_ITEM_ACCESS_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        enabled: process.env.NODE_ENV === 'production',
        hostSafeList: [
          // TODO: After BFF deployment, remove the old url end with .io
          'office2.shippio.io',
          'staging.office2.shippio.io',
          'demo.office2.shippio.io',
          'office.shippio.jp',
          'office.stg.shippio.jp',
          'demo-office.shippio.jp',
        ],
        payload: {
          environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
        },
        checkIgnore: (_isUncaught, args) => {
          // Code here to determine whether or not to send the payload
          // to the Rollbar API
          // return true to ignore the payload
          if (args[1]?.name === 'ChunkLoadError') {
            return true;
          }

          return false;
        },
      });
      window.rollbar = rollbar;
    }

    const handleWheelOnNumber = () => {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    };

    // from https://stackoverflow.com/questions/9712295/disable-scrolling-on-input-type-number/38589039#38589039
    document.addEventListener('wheel', handleWheelOnNumber);

    return () => {
      window.rollbar = null;
      document.removeEventListener('wheel', handleWheelOnNumber);
    };
  }, []);
  return (
    <div className="app-container">
      <ErrorBoundary>
        <Suspense fallback={(<Loading><h6 className="mt-3">Loading...</h6></Loading>)}>
          <SubscriptionEventProvider>
            <AppMain />
          </SubscriptionEventProvider>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default withRouter(App);
