import { gql } from '@apollo/client';

export default gql`
  query countrySearch(
    $searchString: String
  ) {
    countrySearch(
      searchString: $searchString
    ) {
      id
      unLocode
      nameTranslations {
        en
        ja
      }
    }
  }
`;
