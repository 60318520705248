import { gql } from '@apollo/client';

/**
 * tradeDirection, incoterm, pol, and pod fields are nullable on Shipment to enable
 * NSS. The same fields on QuoteLane type are not nullable. To avoid an error returned
 * by the BFF, the relevant fields for QuoteLane are aliased.
 */
export default gql`
  query ChannelSearch(
    $channelId: ID
    $companyId: ID
    $customerTeamId: ID
    $channelTypeFilter: ChannelTypeFilterEnum = all
    $pagination: PaginationInput,
  ) {
    channelSearch(
      channelId: $channelId,
      companyId: $companyId,
      customerTeamId: $customerTeamId,
      channelTypeFilter: $channelTypeFilter,
      pagination: $pagination,
    ) {
      id
      contexts {
        ...on Shipment {
          id
          tradeDirection
          incoterm
          pol {
            id
            unlocode
          }
          pod {
            id
            unlocode
          }
          cargo {
            id
            cargoType
          }
          status
          urgencyLevel
          customerReference
          customerTeam {
            id
            name
            company {
              id
              name
            }
          }
        }
        ...on QuoteLane {
          id
          quoteTradeDirection: tradeDirection
          quoteIncoterm: incoterm
          latestSimulationData {
            cargoType
          }
          quotePol: pol {
            id
            unlocode
          }
          quotePod: pod {
            id
            unlocode
          }
          customerTeam {
            id
            name
            company {
              id
              name
            }
          }
        }
        ...on IdentifiedCustomerPartner {
          id
          companyName
        }
      }
      lastMessage {
        id
        createdAt
      }
      lastReadByCurrentUser {
        lastReadMessageId
      }
      participantsType
      unreadMessageCount
    }
  }
`;
