import { gql } from '@apollo/client';
import { SHIPMENT_CHANNEL_FIELDS } from '../../Channel';

/**
 * Based on this sheet:
 * https://docs.google.com/spreadsheets/d/1eYkcW7XOwQ5APySXpROVHz8lCpUccbgmbv000F8lWDo/edit#gid=0
 */
export default gql`
  fragment OftenChangingFields on Shipment {
    status
    urgencyLevel
    milestones {
      id
      completionDoneAt
      completionEstimatedAt
      etaMismatch
      milestoneType
      status
    }
    assignedInternalUsers {
      id
      name
    }
    ...ChannelFields
  }
  ${SHIPMENT_CHANNEL_FIELDS}
`;
