import { gql } from '@apollo/client';

export default gql`
  query CompanySearchPagination(
    $searchString: String
    $pagination: PaginationInput
  ) {
    companySearchPagination(
      searchString: $searchString
      pagination: $pagination
    ) {
      totalCount
    }
  }
`;
