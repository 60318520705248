import { gql } from '@apollo/client';

export default gql`
  query BillDueDateResolver(
    $customerTeamId: ID!
    $shipmentId: ID!
    $referenceMilestoneType: BillingMilestoneEnum!
  ) {
    billDueDateResolver(
      customerTeamId: $customerTeamId
      shipmentId: $shipmentId
      referenceMilestoneType: $referenceMilestoneType
    )
  }
`;
