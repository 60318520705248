import { gql } from '@apollo/client';

export default gql`
  query quoteRequestSearch(
    $quoteRequestId: ID
  ){
    quoteRequestSearch(
      quoteRequestId: $quoteRequestId
      ) {
      cargoType
      completionStates {
        displayData {
          addressName
          cargoType
          podUnlocode
          polUnlocode
          pricingSectionType
        }
        status
      }
      createdAt
      customerTeam {
        company {
          id
          name
          nameEn
          customerTeams {
            id
          }
        }
        id
        name
      }
      id
      incoterm
      pod {
        id
        unlocode
      }
      pol {
        id
        unlocode
      }
      quoteRequestType
      referenceNumber
      requestDetails {
        cargoTitle
        collectionPlaces {
          addressId
        }
        containerTypes
        containsDangerousGoods
        customsHandling
        deliveryPlaces {
          addressId
        }
        drayageTruckingRequested
        drayageTypes
        insuranceHandling
        isLongCargo
        isNonStackableCargo
        reeferContainerInfo
        remarks
        specialCargoDetails
        specialTruckingRequested
        specialTruckRequestDetails
        truckTypes
      }
      status
      tradeDirection
    }
  }
`;
