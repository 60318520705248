import { gql } from '@apollo/client';

export default gql`
  mutation AssignCustomerEntities(
    $assignToDetails: AssignToInput!
    $assignmentDetails: [AssignmentDetailsInput!]
  ) {
    assignCustomerEntities(
      assignToDetails: $assignToDetails
      assignmentDetails: $assignmentDetails
    ) {
      ...on QuoteLane {
        id
        assignee {
          ...on CustomerUser {
            id
            firstName
            lastName
          }
          ...on UserGroup {
            id
            name
          }
        }
      }
      ...on Shipment {
        id
        assignee {
          ...on CustomerUser {
            id
            firstName
            lastName
          }
          ...on UserGroup {
            id
            name
          }
        }
      }
    }
  }
`;
