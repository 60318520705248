import { gql } from '@apollo/client';

export default gql`
  mutation uploadDailyCleansingDateDiffThresholdCsv(
    $dataCleansingReasonId: ID!
    $attachmentArgs: RemoteDirectUploadLinkInput!
    ) {
    uploadDailyCleansingDateDiffThresholdCsv(
        dataCleansingReasonId: $dataCleansingReasonId
        attachmentArgs: $attachmentArgs
        ) {
      carrierId
      eventType
      futureDateDiffThreshold
      id
      milestoneType
      pastDateDiffThreshold
    }
  }
`;
