import * as types from './types';

const COMPANY_SETTINGS = 'company-settings';
const TEAM_SETTINGS = 'team-settings';
const DIRECTION_SETTINGS = 'direction-settings';

// Load the values from the local storage
const savedCompany = JSON.parse(localStorage.getItem(COMPANY_SETTINGS));
const savedTeam = JSON.parse(localStorage.getItem(TEAM_SETTINGS));
const savedDirection = localStorage.getItem(DIRECTION_SETTINGS) !== 'undefined'
  ? localStorage.getItem(DIRECTION_SETTINGS)
  : null;

const initialQuote = {
  tradeDirection: savedDirection || 'export',
  company: savedCompany || null,
  customerUserTeam: savedTeam || null,
  pricingSectionIds: null,
  teamSettings: null,
  carrierIndexes: null,
  expandedNodes: [],
};

const quote = (state = initialQuote, action) => {
  switch (action.type) {
  case types.TOP_OPTIONS_EDIT: {
    const { tradeDirection, company, customerUserTeam } = action.payload;

    // Save to the local storage
    localStorage.setItem(COMPANY_SETTINGS, JSON.stringify(company));
    localStorage.setItem(TEAM_SETTINGS, JSON.stringify(customerUserTeam));
    localStorage.setItem(DIRECTION_SETTINGS, tradeDirection);

    return {
      ...state,
      tradeDirection,
      company,
      customerUserTeam,
    };
  }

  case types.PRICING_SECTION_IDS_EDIT: {
    const { pricingSectionIds } = action.payload;

    return {
      ...state,
      pricingSectionIds,
    };
  }

  case types.TEAM_SETTINGS_EDIT: {
    const { teamSettings } = action.payload;

    return {
      ...state,
      teamSettings,
    };
  }

  case types.CARRIER_INDEXES_RESET: {
    const carrierIndexes = {};

    return {
      ...state,
      carrierIndexes,
    };
  }

  case types.CARRIER_INDEXES_ADD: {
    const { sectionId, index } = action.payload;
    const carrierIndexes = { ...state.carrierIndexes };
    carrierIndexes[sectionId] = index;

    return {
      ...state,
      carrierIndexes,
    };
  }

  case types.EXPANDED_NODES_EDIT: {
    const { expandedNodes } = action.payload;

    return {
      ...state,
      expandedNodes,
    };
  }

  default:
    return state;
  }
};

export default quote;
