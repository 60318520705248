/* eslint-disable indent */
import * as types from './types';

const COMPANY_SETTINGS = 'company-settings';
const TEAM_SETTINGS = 'team-settings';
const ADDRESS_TYPE = 'address-type';
const DIRECTION_SETTINGS = 'direction-settings';

// Load the values from the local storage
const savedCompany = JSON.parse(localStorage.getItem(COMPANY_SETTINGS));
const savedTeam = JSON.parse(localStorage.getItem(TEAM_SETTINGS));
const savedAddressType = localStorage.getItem(ADDRESS_TYPE);
const savedDirection = localStorage.getItem(DIRECTION_SETTINGS) !== 'undefined'
  ? localStorage.getItem(DIRECTION_SETTINGS)
  : null;

const initialOptions = {
  tradeDirection: savedDirection || 'export',
  company: savedCompany || null,
  customerUserTeam: savedTeam || null,
  addressType: savedAddressType || null,
};

const dataManagement = (state = initialOptions, action) => {
  switch (action.type) {
    case types.TOP_OPTIONS_EDIT: {
      const { company, customerUserTeam } = action.payload;

      // Save to the local storage
      localStorage.setItem(COMPANY_SETTINGS, JSON.stringify(company));
      localStorage.setItem(TEAM_SETTINGS, JSON.stringify(customerUserTeam));
      localStorage.setItem(DIRECTION_SETTINGS, savedDirection);

      return {
        ...state,
        company,
        customerUserTeam,
      };
    }

    case types.ADDRESS_TYPE_EDIT: {
      const { addressType } = action.payload;

      // Save to the local storage
      localStorage.setItem(ADDRESS_TYPE, addressType);

      return {
        ...state,
        addressType,
      };
    }

    default:
      return state;
  }
};

export default dataManagement;
