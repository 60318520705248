import { gql } from '@apollo/client';

export default gql`
  query ShipmentSearchTasks(
    $shipmentId: ID
    $scopedSearchArgs: BackOfficeShipmentSearchInput
    $orderOption: BackOfficeOrderByEnum
    $pagination: PaginationInput
  ) {
    backOfficeShipmentSearch(
      shipmentId: $shipmentId
      scopedSearchArgs: $scopedSearchArgs
      orderOption: $orderOption
      pagination: $pagination
    ) {
      id
      customerTeam {
        id
        name
        company {
          id
          name
        }
        customerUsers {
          id
          name
        }
      }
      tasks {
        id
        status
        title
        taskType
        dueDate
        assignedCustomerUser {
          id
          name
        }
        attachment {
          id
          url
        }
      }
    }
  }
`;
