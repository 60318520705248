import { gql } from '@apollo/client';

export default gql`
  query companySearch($searchString: String, $onlyActive: Boolean, $pagination: PaginationInput, $companyId: ID){
    companySearch(searchString: $searchString, onlyActive: $onlyActive, pagination: $pagination, companyId: $companyId) {
      id
      name
      nameEn
      customerTeams {
        id
      }
    }
  }
`;
