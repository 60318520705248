import { gql } from '@apollo/client';

export default gql`
  mutation upsertProduct(
    $productId: ID,
    $productArgs: ProductInput!,
    $customerTeamId: ID!) {
  upsertProduct(
    productId: $productId,
    productArgs: $productArgs,
    customerTeamId: $customerTeamId) {
      id
    }
  }
`;
