import * as types from './types';

// Initial state can be set here, although can also be fed in through createStore
const initialUser = {
  id: null,
};

const user = (state = initialUser, action) => {
  switch (action.type) {
  case types.SET_CURRENT_USER_ID: {
    const { id } = action.payload;

    return {
      ...state,
      id,
    };
  }

  default:
    return state;
  }
};

export default user;
