import { gql } from '@apollo/client';

export default gql`
  query CarriersManagementSearch(
    $searchString: String
    $pagination: PaginationInput
    $orderOption: RecordOrderInput
  ) {
    carrierSearch(searchString: $searchString, pagination: $pagination, orderOption: $orderOption) {
      id
      name
      carrierType
      supportBkgCargoTracking
      supportCtnCargoTracking
      supportMblCargoTracking
      supportTstVesselTracking
      supportVslVesselTracking
      masterBlPattern
      address
      naccsCode
      scacCode
    }
  }
`;
