import { gql } from '@apollo/client';

export default gql`
  mutation UpsertMemo(
    $memoId: ID
    $memoListId: ID
    $content: String!
  ) {
    upsertMemo(
      memoId: $memoId
      memoListId: $memoListId
      content: $content
    ) {
      id
    }
  }
`;
