import { gql } from '@apollo/client';

export default gql`
  query VoyageSearch(
    $carrierId: ID!,
    $polId: ID!,
    $podId: ID!,
    $transship: [String!],
    $pagination: PaginationInput,
    $includeDrafts: Boolean=false,
    $skipCutOffDateCheck: Boolean=false,
    ) {
    voyageSearch(
      carrierId: $carrierId,
      polId: $polId,
      podId: $podId,
      transship: $transship,
      pagination: $pagination,
      includeDrafts: $includeDrafts,
      skipCutOffDateCheck: $skipCutOffDateCheck,
      ) {
        cfsCutOffDate
        cyCutOffDate
        cyOpenDate
        eta
        etd
        id
        status
        transitTime
        vessel {
          id
          name
        }
        voyageNumber
    }
  }
`;
