import { gql } from '@apollo/client';

export default gql`
  query ChannelSearch(
    $channelId: ID
    $companyId: ID
    $customerTeamId: ID
    $channelTypeFilter: ChannelTypeFilterEnum = all
    $pagination: PaginationInput,
  ) {
    channelSearch(
      channelId: $channelId,
      companyId: $companyId,
      customerTeamId: $customerTeamId,
      channelTypeFilter: $channelTypeFilter,
      pagination: $pagination,
    ) {
      id
      lastMessage {
        id
      }
      lastReadByCurrentUser {
        lastReadMessageId
      }
      unreadMessageCount
    }
  }
`;
