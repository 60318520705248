import { gql } from '@apollo/client';

export default gql`
  mutation uploadFile(
    $attachmentsArgs: ShipmentUploadInput!
  ) {
    uploadFile(attachmentsArgs: $attachmentsArgs) {
      id
      url
    }
  }
`;
