import { gql } from '@apollo/client';

export default gql`
  mutation UpdateMultipleCustomerUserSettings(
    $customerUserId: ID!
    $multiSettingsArgs: [MultiSettingsUpdateInput!]!
  ) {
    updateMultipleCustomerUserSettings(
      customerUserId: $customerUserId
      multiSettingsArgs: $multiSettingsArgs
    ) {
      id
      customerTeamId
      receiveEmailNotifications
      receiveSummaryEmailNotifications
    }
  }
`;
