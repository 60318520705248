import { gql } from '@apollo/client';

export default gql`
  query CarrierSearch($searchString: String, $carrierType: CarrierTypeEnum, $pagination: PaginationInput){
    carrierSearch(searchString: $searchString, carrierType: $carrierType, pagination: $pagination) {
      id
      name
    }
  }
`;
