import { gql } from '@apollo/client';

export default gql`
query IdentifiedCustomerPartnerDetails($icpId: ID) {
  identifiedCustomerPartnerSearch(icpId: $icpId) {
    id
    companyName
    partnerType
    tradePartners {
      id
      customerTeam {
        id
        name
        company {
          name
        }
      }
      sharedAddresses {
        name
        id
      }
    }
  }
}
`;
