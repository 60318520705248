import { gql } from '@apollo/client';

export default gql`
  query ShipmentSearchAttachments(
    $shipmentId: ID
    $scopedSearchArgs: BackOfficeShipmentSearchInput
    $orderOption: BackOfficeOrderByEnum
    $pagination: PaginationInput
  ) {
    backOfficeShipmentSearch(
      shipmentId: $shipmentId
      scopedSearchArgs: $scopedSearchArgs
      orderOption: $orderOption
      pagination: $pagination
    ) {
      id
      availableAttachmentsWithPrivate {
        categories
        createdAt
        id
        identifiedCustomerPartner {
          id
          companyName
        }
        isPrivate
        isRelatedToTask
        name
        uploadedAt
        uploader {
          id
          name
        }
        url
        userVisibility
      }
    }
  }
`;
