import { gql } from '@apollo/client';

// Used for populating a Customer Partner select that requires ID and Name.

export default gql`
  query GetCustomerPartnersBasic($customerTeamId: ID!, $tradePartnerId: ID, $pagination: PaginationInput) {
    tradePartnerSearch(
      customerTeamId: $customerTeamId
      tradePartnerId: $tradePartnerId
      pagination: $pagination
    ) {
      id
      name: companyName
      tradePartnerType
      identifiedCustomerPartner {
        id
      }
    }
  }
`;
