import { gql } from '@apollo/client';

export default gql`
  query quoteRequestSearchPagination(
    $companyId: ID,
    $customerTeamId: ID,
    $status: QuoteRequestStatusEnum,
    $quoteRequestId: ID
    $pagination: PaginationInput
  ) {
    quoteRequestSearchPagination(
      companyId: $companyId
      customerTeamId: $customerTeamId
      status: $status
      quoteRequestId: $quoteRequestId
      pagination: $pagination
    ) {
      totalCount
    }
  }
`;
