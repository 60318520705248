import * as types from './types';

const initialVesselSchedule = {
  voyageIds: null,
};

const quote = (state = initialVesselSchedule, action) => {
  switch (action.type) {
  case types.VOYAGE_IDS_EDIT: {
    const { voyageIds } = action.payload;

    return {
      ...state,
      voyageIds,
    };
  }

  default:
    return state;
  }
};

export default quote;
