import { gql } from '@apollo/client';

export default gql`
  query partnerSearch(
    $partnerId: ID,
    $searchString: String,
    $supportPartnerTypes: [PartnerTypesEnum!],
    $pagination: PaginationInput
  ) {
    partnerSearch(
      partnerId: $partnerId
      searchString: $searchString
      supportPartnerTypes: $supportPartnerTypes,
      pagination: $pagination
    ) {
      id
      internalId
      name
      partnerTypes
    }
  }
`;
