import { gql } from '@apollo/client';

export default gql`
  query tradeDocumentAvailableTypeSearch(
    $shipmentId: ID!
  ) {
    tradeDocumentAvailableTypeSearch(
      shipmentId: $shipmentId
    )
  }
`;
