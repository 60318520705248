import { lazy } from 'react';

const PUBLIC_REDIRECT = {
  to: '/login',
};

const PUBLIC_ROUTES = [
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('../pages/Login')),
  },
  {
    path: '/forgot-password',
    exact: true,
    component: lazy(() => import('../pages/Login/PasswordReset/PasswordResetRequest')),
  },
  {
    path: '/forgot-password/sent',
    exact: true,
    component: lazy(() => import('../pages/Login/PasswordReset/PasswordResetSent')),
  },
  {
    path: '/reset-password',
    exact: true,
    component: lazy(() => import('../pages/Login/PasswordReset/ResetPassword')),
  },
];

const PROTECTED_REDIRECT = {
  to: '/shipments',
};

const PROTECTED_ROUTES = [
  {
    path: '/shipments',
    exact: true,
    component: lazy(() => import('../pages/Shipments')),
  },
  {
    path: '/shipment/:id',
    exact: true,
    component: lazy(() => import('../pages/Shipments')),
  },
  {
    path: '/quote',
    exact: true,
    component: lazy(() => import('../pages/Quote/Quote')),
  },
  {
    path: '/vessel-schedule',
    exact: true,
    component: lazy(() => import('../pages/Quote/VesselSchedule')),
  },
  {
    path: '/accounts/customer-partners',
    exact: true,
    component: lazy(() => import('../pages/Accounts/CustomerPartners/IdentifiedCustomerPartners')),
  },
  {
    path: '/accounts/customer-partners/:icpId',
    exact: true,
    component: lazy(() => import('../pages/Accounts/CustomerPartners/IdentifiedCustomerPartners')),
  },
  {
    path: '/accounts/customer-partners/:icpID/users',
    exact: true,
    component: lazy(() => import('../pages/Accounts/CustomerPartnerUser/CustomerPartnerUser')),
  },
  {
    path: '/companies/:companyId/team/:teamId/user-groups',
    exact: true,
    component: lazy(() => import('../pages/UserGroups/UserGroups')),
  },
  {
    path: '/companies/:companyId/team/:teamId/user-groups/:userGroupId',
    exact: true,
    component: lazy(() => import('../pages/UserGroupMemberships/UserGroupMemberships')),
  },
  {
    path: '/companies/:id',
    exact: true,
    component: lazy(() => import('../pages/Companies/CompaniesContainer')),
  },
  {
    path: '/companies/:id/users',
    exact: true,
    component: lazy(() => import('../pages/CustomerUsers/CustomerUsers')),
  },
  {
    path: '/companies',
    exact: true,
    component: lazy(() => import('../pages/Companies/CompaniesContainer')),
  },
  {
    path: '/products',
    exact: true,
    component: lazy(() => import('../pages/Products')),
  },
  {
    path: '/products/:id',
    exact: true,
    component: lazy(() => import('../pages/Products')),
  },
  {
    path: '/products/create',
    exact: true,
    component: lazy(() => import('../pages/Products')),
  },
  {
    path: '/customer-partners',
    exact: true,
    component: lazy(() => import('../pages/CustomerPartners/CustomerPartners')),
  },
  {
    path: '/customer-partners/:id',
    exact: true,
    component: lazy(() => import('../pages/CustomerPartners/CustomerPartners')),
  },
  {
    path: '/customer-partners/create',
    exact: true,
    component: lazy(() => import('../pages/CustomerPartners/CustomerPartners')),
  },
  {
    path: '/places',
    exact: true,
    component: lazy(() => import('../pages/Places')),
  },
  {
    path: '/places/:id',
    exact: true,
    component: lazy(() => import('../pages/Places')),
  },
  {
    path: '/places/create',
    exact: true,
    component: lazy(() => import('../pages/Places')),
  },
  {
    path: '/settings',
    exact: true,
    component: lazy(() => import('../pages/Settings')),
  },
  {
    path: '/simple-operation/daily-cleansing',
    exact: true,
    component: lazy(() => import('../pages/SimpleOperation/DailyCleansing')),
  },
  {
    path: '/chat',
    exact: true,
    component: lazy(() => import('../pages/Chat')),
  },
  {
    path: '/quote-requests',
    exact: true,
    component: lazy(() => import('../pages/QuoteRequests')),
  },
  {
    path: '/quote-request/:id',
    exact: true,
    component: lazy(() => import('../pages/QuoteRequests')),
  },
  {
    path: '/carriers',
    exact: true,
    component: lazy(() => import('../pages/Carriers/CarriersContainer')),
  },
  {
    path: '/vessels',
    exact: true,
    component: lazy(() => import('../pages/Vessels/VesselsContainer')),
  },
  {
    path: '/milestone-log',
    exact: true,
    component: lazy(() => import('../pages/MilestoneLog/MilestoneLog')),
  },
];

export {
  PUBLIC_REDIRECT,
  PUBLIC_ROUTES,
  PROTECTED_ROUTES,
  PROTECTED_REDIRECT,
};
