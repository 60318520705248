import { gql } from '@apollo/client';

export default gql`
  fragment ChannelMessageFields on ChannelMessage {
    id
    channelMessageType
    sender {
      id
      firstName
      lastName
      senderType
      companyName
    }
    createdAt
    content
    attachment {
      name
      url
      isPrivate
      deletedAt
    }
  }
`;
