import { gql } from '@apollo/client';

export default gql`
  query BackOfficeShipmentSearchPagination(
    $shipmentId: ID
    $scopedSearchArgs: BackOfficeShipmentSearchInput
    $orderOption: BackOfficeOrderByEnum
    $pagination: PaginationInput
  ) {
    backOfficeShipmentSearchPagination(
      shipmentId: $shipmentId
      scopedSearchArgs: $scopedSearchArgs
      orderOption: $orderOption
      pagination: $pagination
    ) {
      totalCount
    }
  }
`;
