import { gql } from '@apollo/client';

export default gql`
query CustomerTeamsBillSettingSearch($customerTeamId: ID, $customerTeamsBillSettingId: ID) {
  customerTeamsBillSettingSearch(customerTeamId: $customerTeamId, customerTeamsBillSettingId: $customerTeamsBillSettingId) {
    billingType
    contractFrom
    contractTo
    id
  }
}
`;
