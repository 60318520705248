import { createStore, combineReducers } from 'redux';
import reducers from '../reducers';

export default function configureStore() {
  /* eslint-disable no-underscore-dangle */
  return createStore(
    combineReducers({
      ...reducers,
    }),
    {},
    /* eslint-disable no-underscore-dangle */
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    /* eslint-enable */
  );
}
