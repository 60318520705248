import { gql } from '@apollo/client';

export default gql`
  mutation createCompany($args: CompanyInput!, $serviceType: Int) {
    createCompany(companyArgs: $args, serviceType: $serviceType) {
      id
      name
      nameEn
    }
  }
`;
