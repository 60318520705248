import { gql } from '@apollo/client';

export default gql`
  subscription OnPostEvent ($userId: ID!, $userType: UserType!) {
    onPostEvent(userId: $userId, userType: $userType) {
      ...on NotificationEvent {
        userId
      }
      ...on ChannelEvent {
        userId
        payload {
          id
          isNewChannel
          participationType
          contexts {
            id
            type
          }
        }
      }
    }
  }
`;
