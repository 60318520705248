import { gql } from '@apollo/client';

export default gql`
  mutation createTradeDocument(
    $shipmentId: ID!
    $documentType: TradeDocumentTypeEnum!
    $documentInput: CreationInput
  ) {
    createTradeDocument(
      shipmentId: $shipmentId
      documentType: $documentType
      documentInput: $documentInput
    ) {
      id
      url
    }
  }
`;
