import { gql } from '@apollo/client';

export default gql`
mutation UpsertCustomerTeamsBillSetting($settingsArgs: CustomerTeamsBillSettingInput!, $customerTeamId: ID, $customerTeamsBillSettingId: ID) {
  upsertCustomerTeamsBillSetting(settingsArgs: $settingsArgs, customerTeamId: $customerTeamId, customerTeamsBillSettingId: $customerTeamsBillSettingId) {
    billingType
    contractFrom
    contractTo
    id
  }
}`;
