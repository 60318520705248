import { gql } from '@apollo/client';

export default gql`
  mutation unfinalizeShipment($shipmentId: ID!) {
      unfinalizeShipment(shipmentId: $shipmentId) {
          id
          finalized
      }
  }
`;
