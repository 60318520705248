import { gql } from '@apollo/client';

export default gql`
  mutation UpdateShipment(
    $shipmentId: ID!
    $shipmentArgs: BoShipmentUpdateInput!
  ) {
    boUpdateShipment(
      shipmentId: $shipmentId
      shipmentArgs: $shipmentArgs
    ) {
      id
      expirationDate
    }
  }
`;
