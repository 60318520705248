import { gql } from '@apollo/client';
import { SHIPMENT_DETAILED } from '../../fragments/Shipment';

export default gql`
  query BackOfficeShipmentSearch(
    $shipmentId: ID
    $scopedSearchArgs: BackOfficeShipmentSearchInput
    $orderOption: BackOfficeOrderByEnum
    $pagination: PaginationInput
  ) {
    backOfficeShipmentSearch(
      shipmentId: $shipmentId
      scopedSearchArgs: $scopedSearchArgs
      orderOption: $orderOption
      pagination: $pagination
    ) {
      ...DetailedFields
    }
  }
  ${SHIPMENT_DETAILED}
`;
