import { gql } from '@apollo/client';
import SHIPMENT_BASIC from './Basic';
import SHIPMENT_NEVER_CHANGE from './Frequency/Never';
import SHIPMENT_SELDOM_CHANGE from './Frequency/Seldom';
import SHIPMENT_OFTEN_CHANGE from './Frequency/Often';

export default gql`
  fragment DetailedFields on Shipment {
    id
    ...BasicFields,
    ...NeverChangingFields,
    ...SeldomChangingFields,
    ...OftenChangingFields,
  },
  ${SHIPMENT_BASIC},
  ${SHIPMENT_NEVER_CHANGE},
  ${SHIPMENT_SELDOM_CHANGE},
  ${SHIPMENT_OFTEN_CHANGE},
`;
