import { gql } from '@apollo/client';

export default gql`
  mutation UpdateCustomerTeam(
    $customerTeamId: ID!
    $customerTeamBasicInfoInput: CustomerTeamDataInput
    $customerTeamTradeInfoInput: TradeInfoInput
    $customerTeamPaymentInfoInput: PaymentInfoInput
  ) {
    updateCustomerTeam(
      customerTeamId: $customerTeamId
      customerTeamDataInput: $customerTeamBasicInfoInput
      tradeInfoInput: $customerTeamTradeInfoInput
      paymentInfoInput: $customerTeamPaymentInfoInput
    ) {
      id
    }
  }
`;
