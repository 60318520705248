import { gql } from '@apollo/client';

export default gql`
  query GetCustomerTeamInformation($customerTeamId: ID){
    customerTeamSearch(customerTeamId: $customerTeamId) {
      id
      referenceNumber,
      serviceType {
        type
        value
      }
      company {
        referenceNumber
      }
      settings {
        exportQuote {
          containers
          drayageChassisTypes
          incoterm
          truckTypes
        }
        importQuote {
          containers
          drayageChassisTypes
          incoterm
          truckTypes
        }
      }
      tradePartners {
        id
        tradePartnerType
        shipper {
          address
          country {
          nameTranslations {
            en
            ja
          }
        }
          companyName
        }
        consignee {
          address
          country {
            nameTranslations {
              en
              ja
            }
          }
          companyName
        }
        notifyParty {
          address
          country {
            nameTranslations {
              en
              ja
            }
          }
          companyName
        }
      }
      miscAttachments {
        id
        name
        uploadedAt
        uploader {
          id
          name
        }
        url
      }
      data {
        name
        country {
          nameTranslations {
            en
            ja
          }
        }
        assignedCustomerSuccess {
          id
          name
        }
        assignedOperator {
          id
          name
        }
        assignedSales {
          id
          name
        }
        countryCode
        postalCode
        addressEn
        addressJa
        phoneNumber
        faxNumber
        corporateIdentificationNumber
      }
      paymentInfo {
        referenceDate
        deferredPaymentMonth
        deferredPaymentDate
      }
      tradeInfo {
        deferredPaymentInfo {
          comprehensiveDeferredPayment
          comprehensiveDeferredPaymentNumber
        }
        insuranceInfo {
          comprehensiveInsurance
          comprehensiveInsuranceDueDate
          comprehensiveInsuranceNumber
        }
        realtimeBankAccount {
          accountNumber
          accountType
          bankCode
          bankName
          branchCode
          branchName
          name
          realtimeBank
        }
        jastproCode
      }
      insuranceAttachment {
        id
        url
        name
      }
    }
  }
`;
