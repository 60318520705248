import { gql } from '@apollo/client';

export default gql`
  mutation InviteToCustomerTeam($teamId: ID!, $args: CustomerUserInviteInput!) {
    inviteToCustomerTeam(customerTeamId: $teamId, customerUserArgs: $args) {
      firstName
      lastName
    }
  }
`;
