import { gql } from '@apollo/client';

export default gql`
  query addressSearch($customerTeamId: ID!, $addressId: ID, $addressType: AddressTypeEnum $pagination: PaginationInput) {
    addressSearch(
      customerTeamId: $customerTeamId
      addressId: $addressId
      addressType: $addressType
      pagination: $pagination
    ) {
      id
      address
      addressType
      contactName
      customerTeam {
        company {
          name
        }
      }
      contactPhoneNumber
      countryCode
      customerTeamId
      isShared
      latitude
      longitude
      name
      notes
      postalCode
      tradePartnerId
      tradePartner {
        companyName
      }
    }
  }
`;
