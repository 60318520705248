import { gql } from '@apollo/client';

export default gql`
  query ShipmentSearchBasicInfo(
    $pagination: PaginationInput
    $searchString: String
    $shipmentId: ID
    $tradeDirection: TradeDirectionEnum
  ) {
    backOfficeShipmentSearch(
      pagination: $pagination
      scopedSearchArgs: {
        searchString: $searchString
        tradeDirection: $tradeDirection
      }
      shipmentId: $shipmentId
    ) {
      assignedInternalUsers {
        name
      }
      cancelledAt
      cargo {
        id
        cargoDescription
        cargoType
      }
      customerReference
      customerTeam {
        id
        name
        company {
          name
        }
        customerUsers {
          id
          name
        }
      }
      expirationDate
      id
      incoterm
      internalReference
      pod {
        id
        unlocode
      }
      pol {
        id
        unlocode
      }
      responsibilityType
      status
      tradeDirection
      urgencyLevel
    }
  }
`;
