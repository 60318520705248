import { gql } from '@apollo/client';

export default gql`
  query quoteRequestSearch(
    $companyId: ID,
    $customerTeamId: ID,
    $status: QuoteRequestStatusEnum,
    $quoteRequestId: ID
    $pagination: PaginationInput
  ) {
    quoteRequestSearch(
      companyId: $companyId
      customerTeamId: $customerTeamId
      status: $status
      quoteRequestId: $quoteRequestId
      pagination: $pagination
    ) {
      assignedInternalUser {
        id
        name
      }
      cargoType
      createdAt
      customerTeam {
        id
        company {
          id
          name
        }
        name
      }
      id
      incoterm
      pod {
        id
        unlocode
      }
      pol {
        id
        unlocode
      }
      quoteRequestType
      referenceNumber
      status
      tradeDirection
    }
  }
`;
