import { gql } from '@apollo/client';

export default gql`
  query tradePartnerSearch(
    $customerTeamId: ID!, 
    $tradePartnerId: ID, 
    $tradePartnerTypes: [TradePartnerTypeEnum!], 
    $pagination: PaginationInput) {
      tradePartnerSearch(
        customerTeamId: $customerTeamId
        tradePartnerId: $tradePartnerId
        tradePartnerTypes: $tradePartnerTypes
        pagination: $pagination
      ) {
        id
        blType
        freightAsArranged
        customerTeamId
        tradePartnerType
        freightTerms
        identifiedCustomerPartner {
          addresses {
            id
            name
          }
          id
        }
        numberOfCopies
        numberOfOriginals
        paymentPlace
        notifyParty {
          address
          companyName
          country {
            nameTranslations {
              en
              ja
            }
          }
          contactName
          countryCode
          email
          faxNumber
          phoneNumber
        }
        consignee {
          address
          companyName
          country {
            nameTranslations {
              en
              ja
            }
          }
          contactName
          countryCode
          email
          faxNumber
          phoneNumber
        }
        shipper {
          address
          companyName
          country {
            nameTranslations {
              en
              ja
            }
          }
          contactName
          countryCode
          email
          faxNumber
          phoneNumber
        }
        warehouse {
          address
          companyName
          country {
            nameTranslations {
              en
              ja
            }
          }
          contactName
          countryCode
          email
          faxNumber
          phoneNumber
        }
    }
  }
`;
