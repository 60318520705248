import { gql } from '@apollo/client';

export default gql`
  mutation AssignToShipment(
    $shipmentId: ID!
    $assignmentDetails: [AssignmentDetailsInput!]
  ) {
    assignToShipment(
      shipmentId: $shipmentId
      assignmentDetails: $assignmentDetails
    ) {
      id
      assignee {
        ...on CustomerUser {
          id
          firstName
          lastName
        }
        ...on UserGroup {
          id
          name
        }
      }
    }
  }
`;
