import { gql } from '@apollo/client';

export default gql`
  mutation UpdateTaxOrExchangeRates(
    $shipmentId: ID!
    $exchangeRates: ExchangeRatesInput
    $taxRate: Float
  ) {
    updateTaxOrExchangeRates(
      shipmentId: $shipmentId
      exchangeRates: $exchangeRates
      taxRate: $taxRate
    ) {
      id
    }
  }
`;
