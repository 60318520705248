import { gql } from '@apollo/client';

export default gql`
  query ShipmentSearchInvoices(
    $shipmentId: ID
    $scopedSearchArgs: BackOfficeShipmentSearchInput
    $orderOption: BackOfficeOrderByEnum
    $pagination: PaginationInput
  ) {
    backOfficeShipmentSearch(
      shipmentId: $shipmentId
      scopedSearchArgs: $scopedSearchArgs
      orderOption: $orderOption
      pagination: $pagination
    ) {
      id
      billItemSections {
        billItems {
          billable
          billItemType
          computedAmounts {
            totalUntaxed
            totalCost
          }
          cost
          currency
          description
          draftData {
            billable
            billItemType
            computedAmounts {
              totalUntaxed
              totalCost
            }
            cost
            currency
            description
            quantity
            taxType
            unit
            unitPrice
            partner {
              id
              name
            }
            paymentOnBehalf
          }
          draftOnly
          id
          quantity
          taxType
          unit
          unitPrice
          partner {
            id
            name
          }
          paymentOnBehalf
        }
        id
        isFixed
        sectionType
      }
      bills {
        billType
        billValues {
          totalBilledAmountJpy
        }
        dueDate
        id
        issueDate
        pdfFile {
          url
        }
        status
      }
      cargo {
        id
        cargoType
      }
      computedAmounts {
        profitMargin
        profitMarginWithDraft
        totalBilledAmount
        totalBilledAmountWithDraft
        totalMarginPercentage
        totalMarginPercentageWithDraft
        totalTax
        totalTaxWithDraft
      }
      customerTeam {
        id
        name
        company {
          name
        }
        customerUsers {
          id
          name
        }
      }
      exchangeRates {
        JPY {
          USD
        }
        USD {
          JPY
        }
      }
      finalized
      status
      tradeDirection
      taxRate
    }
  }
`;
