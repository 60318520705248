import { gql } from '@apollo/client';

export default gql`
  query milestoneLogSearchPagination(
    $pagination: PaginationInput
    $shipmentInternalReference: String
  ) {
    milestoneLogSearchPagination(
      shipmentInternalReference: $shipmentInternalReference
      pagination: $pagination
    ) {
      totalCount
    }
  }
`;
