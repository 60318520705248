import { gql } from '@apollo/client';

export default gql`
  query ShipmentSearchMilestones(
    $shipmentId: ID
    $scopedSearchArgs: BackOfficeShipmentSearchInput
    $orderOption: BackOfficeOrderByEnum
    $pagination: PaginationInput
  ) {
    backOfficeShipmentSearch(
      shipmentId: $shipmentId
      scopedSearchArgs: $scopedSearchArgs
      orderOption: $orderOption
      pagination: $pagination
    ) {
      id
      autoTrackingStatus
      cargo {
        id
        cargoType
        containers {
          containerNumber
          id
          collectionChassisType
          deliveryChassisType
        }
      }
      customerTeam {
        id
        name
        company {
          name
        }
        customerUsers {
          id
          name
        }
      }
      nssFclInitialCleansingCompleted
      milestones {
        id
        milestoneEvents {
          id
          accuracy
          addressId
          address {
            name
            address
            postalCode
          }
          carContactInfo {
            carNumber
            companyName
            driverName
            phoneNumber
          }
          container {
            id
            containerNumber
            containerSealNumber
            collectionChassisType
            deliveryChassisType
          }
          completionDoneAt
          completionDoneAtHasTime
          completionEstimatedAt
          completionEstimatedAtHasTime
          detailedReason
          status
          eventName
          drayageReservationStatus
          milestoneId
        }
        completionDoneAt
        completionDoneAtHasTime
        completionEstimatedAt
        completionEstimatedAtHasTime
        etaMismatch
        isDeletable
        lastUpdatedTime
        milestoneType
        port {
          id
          name
          unlocode
          portType
        }
        vesselName
        vesselId
        voyageNumber
        status
        vessel {
          id
          imo
        }
      }
      responsibilityType
      status
      tradeDirection
      urgencyLevel
    }
  }
`;
