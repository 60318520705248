import { gql } from '@apollo/client';

export default gql`
mutation UpdateIdentifiedCustomerPartner(
  $icpId: ID!
  $updateArgs: IdentifiedCustomerPartnerUpdateInput!
) {
  updateIdentifiedCustomerPartner(icpId: $icpId, updateArgs: $updateArgs) {
    id
    companyName
  }
}
`;
