const TOP_OPTIONS_EDIT = 'app/quote/editTopOptions';
const PRICING_SECTION_IDS_EDIT = 'app/quote/editPricingSectionIds';
const TEAM_SETTINGS_EDIT = 'app/quote/editTeamSettings';
const CARRIER_INDEXES_RESET = 'app/quote/resetCarrierIndexes';
const CARRIER_INDEXES_ADD = 'app/quote/addCarrierIndex';
const EXPANDED_NODES_EDIT = 'app/quote/editExpandedNodes';

export {
  TOP_OPTIONS_EDIT,
  PRICING_SECTION_IDS_EDIT,
  TEAM_SETTINGS_EDIT,
  CARRIER_INDEXES_RESET,
  CARRIER_INDEXES_ADD,
  EXPANDED_NODES_EDIT,
};
