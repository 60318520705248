import { gql } from '@apollo/client';

export default gql`
  mutation UpsertVessel(
    $vesselId: ID
    $vesselInput: VesselInput!
  ) {
    upsertVessel(
      vesselId: $vesselId
      vesselInput: $vesselInput
    ) {
      id
    }
  }
`;
