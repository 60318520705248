import { gql } from '@apollo/client';

export default gql`
  query CompanyCustomerUserSearch(
    $companyId: ID
    $pagination: PaginationInput
  ) {
    customerUserSearch(
      companyId: $companyId
      pagination: $pagination
    ) {
      activityRateFor30Days
      customerTeams {
        id
        name
      }
      customerTeamsUserSettings {
        id
        customerTeamId
        receiveEmailNotifications
        receiveSummaryEmailNotifications
      }
      email
      firstName
      id
      lastActivityAt
      lastName
      status
      userType
    }
  }
`;
