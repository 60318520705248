/* eslint-disable indent */
import * as types from './types';

const initialShipmentDetails = {
  mode: 'details',
};

const dataManagement = (state = initialShipmentDetails, action) => {
  switch (action.type) {
    case types.MODE_EDIT: {
      const { mode } = action.payload;

      return {
        ...state,
        mode,
      };
    }

    default:
      return state;
  }
};

export default dataManagement;
