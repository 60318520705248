import { gql } from '@apollo/client';

export default gql`
  query PricingItemSuggestion($tradeDirection: TradeDirectionEnum!, $pricingSectionType: PricingSectionTypeEnum!, $cargoType: CargoTypeEnum!, $incoterm: IncotermEnum!){
    pricingItemSuggestionsSearch(tradeDirection: $tradeDirection, pricingSectionType: $pricingSectionType, cargoType: $cargoType, incoterm: $incoterm){
      airUnit,
      description,
      fclUnit,
      incoterm,
      lclUnit,
      pricingItemType,
      taxType,
      partnerTypes,
    }
  }
`;
