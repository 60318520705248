import { gql } from '@apollo/client';

export default gql`
  mutation CreateChannel(
    $channelContexts: [ChannelContextInput!]!
    $customerTeamId: ID!
    $content: String
    $participantsType: ChannelParticipantsTypeEnum
  ) {
    createChannel(
      channelContexts: $channelContexts
      customerTeamId: $customerTeamId
      content: $content
      participantsType: $participantsType
    ) {
      id
      lastMessage {
        id
      }
    }
  }
`;
