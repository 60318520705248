import { gql } from '@apollo/client';

export default gql`
  mutation updateTask(
    $taskId: ID!
    $taskArgs: TaskUpdateInput
    $assigneeId: ID
  ) {
    updateTask(
      taskId: $taskId
      taskArgs: $taskArgs
      assigneeId: $assigneeId
    ) {
      id
    }
  }
`;
