import { gql } from '@apollo/client';

export default gql`
  mutation updateGlobalSetting(
    $settings: GlobalSettingsInput!
  ) {
    updateGlobalSetting(
      settings: $settings
    ) {
      id
    }
  }
`;
