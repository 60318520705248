import { gql } from '@apollo/client';

export default gql`
  query ShipmentSearchLinkedICPs(
    $shipmentId: ID
  ) {
    backOfficeShipmentSearch(
      shipmentId: $shipmentId
    ) {
      status
      linkedIcps {
        id
        companyName
      }
    }
  }
`;
