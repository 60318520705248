import { gql } from '@apollo/client';

export default gql`
  query PortSearch(
    $searchString: String,
    $portType: PortTypeEnum,
    $onlyActivePol: Boolean,
    $onlyActivePod: Boolean,
    $portId: ID,
    $pagination: PaginationInput,
  ) {
    portSearch(
      searchString: $searchString,
      portType: $portType,
      onlyActivePol: $onlyActivePol,
      onlyActivePod: $onlyActivePod,
      portId: $portId,
      pagination: $pagination,
      ) {
      id
      unlocode
      name
      normalizedName
      portType
    }
  }
`;
