import { gql } from '@apollo/client';

export default gql`
  query PricingSectionSearch($customerTeamId: ID!, $tradeDirection: TradeDirectionEnum, $pricingSectionId: ID){
    pricingSectionSearch(customerTeamId: $customerTeamId, tradeDirection: $tradeDirection, pricingSectionId: $pricingSectionId) {
      id
      pricingItems {
        id
        currency
        description
        inHouse
        paymentOnBehalf
        taxType
        unit
        unitPrice
        cost
        criteria {
          containerType
          drayageChassisType
          address {
            id
            name
          }
          truckType
        }
        expirationDate
        secondaryCost
        secondaryUnitPrice
        status
        pricingItemType
        partner {
          id
          name
        }
      }
      meta {
        cargoType
      }
      partner {
        id
        name
      }
    }
  }
`;
