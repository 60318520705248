import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loaders';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const Loading = ({ children }) => (
  <div className="loader-container">
    <div className="loader-container-inner">
      <div className="text-center">
        <Loader type="ball-pulse-rise" />
      </div>
      {children}
    </div>
  </div>
);

Loading.propTypes = propTypes;

export default Loading;
