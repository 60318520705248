import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ERRORS } from '../utils/constants/error';

// Currently cannot be implemented via hooks
// Not sure where this component should sit within directory
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  // EventListener for error is added to handle 503 error that is not caught by ErrorBoundary
  componentDidMount() {
    window.addEventListener('error', this.onUnhandledRejection.bind(this));
  }

  componentDidCatch(error, errorInfo) {
    // Todo: Rollbar handling should be added here
    console.log(error, errorInfo);
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.onUnhandledRejection);
  }

  onUnhandledRejection(event) {
    if (event?.error?.name === ERRORS.maintenance.name) {
      this.setState({
        hasError: true,
        error: event.error,
      });
    }
  }

  render() {
    const { error, hasError } = this.state;
    const { children } = this.props;

    if (hasError && error) {
      switch (error.name) {
      case ERRORS.maintenance.name:
        return <h1>メンテナンス中です。現在はご利用いただけません。</h1>;
      default:
        return <h1>予期せぬ不具合が生じました。エンジニアに問い合わせてください。</h1>;
      }
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
