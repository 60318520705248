import { gql } from '@apollo/client';
import { CHANNEL_MESSAGE_FIELDS } from '../fragments/Channel';

export default gql`
  mutation CreateChannelMessage(
    $channelId: ID!
    $content: String
  ) {
    createChannelMessage(
      channelId: $channelId
      content: $content
    ) {
      ...ChannelMessageFields
    }
  }
  ${CHANNEL_MESSAGE_FIELDS}
`;
