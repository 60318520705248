import { gql } from '@apollo/client';

export default gql`
  mutation AcceptPreShipment($shipmentId: ID!) {
    acceptPreShipment(shipmentId: $shipmentId) {
      id
      status
    }
  }
`;
