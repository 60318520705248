import { gql } from '@apollo/client';

export default gql`
  mutation SetPassword(
    $password: String!
    $passwordConfirmation: String!
    $role: String
    $resetPasswordToken: String
  ) {
    setPassword(
      password: $password,
      passwordConfirmation: $passwordConfirmation,
      role: $role,
      resetPasswordToken: $resetPasswordToken,
    ) {
      loginToken
    }
  }
`;
