import { gql } from '@apollo/client';

export default gql`
mutation LinkICPtoCustomerPartner(
  $icpId: ID!
  $tradePartnerId: ID!
  $addressIds: [ID!]!
) {
  linkIcpToTradePartner(
    icpId: $icpId
    tradePartnerId: $tradePartnerId
    addressIds: $addressIds
  ) {
    addresses {
      address
      id
    }
    companyName
    id
  }
}
`;
