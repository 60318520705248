import { gql } from '@apollo/client';

export default gql`
  query CompanyCustomerUserSearchPagination(
    $companyId: ID
    $pagination: PaginationInput
  ) {
    customerUserSearchPagination(
      companyId: $companyId
      pagination: $pagination
    ) {
      totalCount
    }
  }
`;
