import { gql } from '@apollo/client';

export default gql`
query GetMilestoneLog(
  $pagination: PaginationInput
  $shipmentInternalReference: String
) {
  milestoneLogSearch(
    shipmentInternalReference: $shipmentInternalReference
    pagination: $pagination
  ) {
    id
    changeTime
    changeType
    milestone {
      id
      milestoneType
      shipment {
        id
        internalReference
        tradeDirection
      }
    }
    newState {
      accuracy
      address{
        id
        address
      }
      completionDoneAt
      completionDoneAtHasTime
      drayageReservationStatus
      expectedDate
      hasTime
      eventName
      eventStatus
      detailedReason
      status
      port {
        id
        unlocode
      }
      vesselName
      voyageNumber
    }
  }
}
`;
