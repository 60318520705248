import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useApolloClient, useLazyQuery } from '@apollo/client';

import { ON_POST_EVENT } from '../graphql/subscriptions';
import { CHANNEL_SEARCH } from '../graphql/queries';

const SubscriptionEventContext = React.createContext();

const TYPENAME_KEY = '__typename';
const isChannelEvent = (event) => event?.[TYPENAME_KEY] === 'ChannelEvent';

const SubscriptionEventProvider = (props) => {
  const client = useApolloClient();
  const currentUserId = useSelector((state) => state.user.id);

  const { isChatOpen } = useSelector((state) => state.chat);
  const [channelEventData, setChannelEventData] = useState(null);

  // Updates chat page list by updating values in cache
  const [getChannelUnreadCount] = useLazyQuery(CHANNEL_SEARCH);

  /**
   * Clear Channel Event  Data
   */
  const clearChannelEventData = () => setChannelEventData(null);

  /**
   * If chat is closed, but we receive chat data, clear the data.
   */
  useEffect(() => {
    if (!isChatOpen) {
      clearChannelEventData();
    }
  }, [isChatOpen]);

  const [subscription, setSubscription] = useState(null);
  const [tryCount, setTryCount] = useState(0);
  const resetTryCount = () => {
    if (tryCount !== 0) {
      setTryCount(0);
    }
  };

  /**
   * Subscribe only once, when dependencies become available.
   */
  useEffect(() => {
    if (tryCount >= 12 || subscription !== null || !currentUserId) return;

    // Why using client.subscribe over useSubscription react hook?
    // Because on strict mode it fires off twice:
    // https://github.com/apollographql/apollo-client/issues/6037
    // This way we can control the number of times we subscribe,
    // although this technically is only an issue in dev mode, it will
    // cause behaviour that doesn't represent prod.
    const observable = client.subscribe({
      query: ON_POST_EVENT,
      variables: {
        userId: currentUserId,
        userType: 'Staff',
      },
    });

    if (!observable) return;

    observable.subscribe({
      next({ data }) {
        if (!data.onPostEvent) return;

        resetTryCount();

        const { onPostEvent } = data;
        if (isChannelEvent(onPostEvent)) {
          setChannelEventData(onPostEvent.payload);
          getChannelUnreadCount({
            variables: { channelId: onPostEvent.payload.id },
          });
        }
      },
      error() {
        setTimeout(() => {
          setTryCount((s) => s + 1);
          setSubscription(null);
        }, 5000);
      },
    });
    setSubscription(observable);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId, subscription]);

  const availableProps = {
    // only consumers who consume this value will re-render
    // so not all components will under this provider
    channelEventData,
    clearChannelEventData,
  };

  return (
    <SubscriptionEventContext.Provider
      value={availableProps}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

const useSubscriptionContext = () => useContext(SubscriptionEventContext);

export {
  SubscriptionEventProvider,
  useSubscriptionContext,
};
