import { gql } from '@apollo/client';

export default gql`
  query BillItemSuggestionsSearch($billItemSectionId: ID!,){
    billItemSuggestionsSearch(billItemSectionId: $billItemSectionId){
      airUnit,
      description,
      fclUnit,
      lclUnit,
      billItemType,
      taxType,
      partnerTypes,
    }
  }
`;
