import { gql } from '@apollo/client';

export default gql`
  query ShipmentSearchDetailedInfo(
    $shipmentId: ID
    $scopedSearchArgs: BackOfficeShipmentSearchInput
    $orderOption: BackOfficeOrderByEnum
    $pagination: PaginationInput
  ) {
    backOfficeShipmentSearch(
      shipmentId: $shipmentId
      scopedSearchArgs: $scopedSearchArgs
      orderOption: $orderOption
      pagination: $pagination
    ) {
      id
      assignedInternalUsers {
        id
        name
      }
      autoTrackingEnabled
      blNumberOfCopies
      blNumberOfOriginals
      blPaymentPlace
      blType
      bookingNumber
      cargo {
        id
        arrivalNoticeRemarks
        cargoDescription
        cargoRemarks
        cargoType
        collectionTruckType
        customsHandling
        descriptionOfGoods
        deliveryTruckType
        finalDestination
        hsCode
        insuranceHandling
        invoiceNumbers
        landTransportationRemarks
        placeOfDelivery
        placeOfReceipt
        preCarriageBy
        purchaseOrderNumbers
        otherApplicableLaws
        shippingInstructionRemarks
        shippingMarks
        afterPackingData {
          packagingType
          quantity
          rtCwWmValue
          totalVolume
          totalVolumeWeight
          totalWeight
        }
        beforePackingData {
          packagingType
          quantity
          rtCwWmValue
          totalVolume
          totalVolumeWeight
          totalWeight
        }
        containers {
          id
          deliveryChassisType
          collectionChassisType
          containerNumber
          containerSealNumber
          containerType
          pickupDatetime
        }
        containerPickupDetails {
          address
          companyName
          confirmationNumber
          naccsCode
          personInCharge
          phoneNumber
          pickupDatetime
          pickupRemarks
        }
        cargoProducts {
          id
          name
          productId
          length
          width
          height
          weight
          quantity
          packagingType
        }
        collectionAddressId
        collectionAddressSnapshot {
          address
          contactName
          contactPhoneNumber
          name
          notes
        }
        deliveryAddressId
        deliveryAddressSnapshot {
          address
          contactName
          contactPhoneNumber
          name
          notes
        }
      }
      cargoReleaseBlNumberOfCopies
      cargoReleaseBlNumberOfOriginals
      cargoReleaseBlPaymentPlace
      cargoReleaseBlType
      cargoReleaseFreightTerms
      carrier {
        id
        name
      }
      customerReference
      customerTeam {
        id
        name
        company {
          name
        }
        customerUsers {
          id
          name
        }
      }
      dem
      det
      drayageDomesticRequested
      drayageOverseasRequested
      drayageRequestsDomestic {
        addressId
        drayageChassisType
        truckCount
      }
      drayageRequestsOverseas {
        addressId
        drayageChassisType
        truckCount
      }
      freetimeLimitDate
      freightAsArranged
      freightTerms
      houseBlShipper {
        id
        customerTeamId
        tradePartnerDetails {
          address
          companyName
          contactName
          email
          faxNumber
          phoneNumber
        }

        # Disable apollo caching for that object
        __typename @skip(if: true)
      }
      houseBlConsignee {
        id
        customerTeamId
        tradePartnerDetails {
          address
          companyName
          contactName
          email
          faxNumber
          phoneNumber
        }
        notifyPartyDetails {
          address
          companyName
          contactName
          email
          faxNumber
          phoneNumber
        }
        notifySameAsTradePartner

        # Disable apollo caching for that object
        __typename @skip(if: true)
      }
      houseBlNumber
      houseBlIssuePlace
      houseBlIssueDate
      incoterm
      internalReference
      masterBlCargoReleasePartner {
        tradePartnerDetails {
          address
          companyName
          contactName
          countryCode
          email
          faxNumber
          phoneNumber
        }
      }
      masterBlConsignee {
        id
        customerTeamId
        tradePartnerDetails {
          address
          companyName
          contactName
          email
          faxNumber
          phoneNumber
        }
        notifyPartyDetails {
          address
          companyName
          contactName
          email
          faxNumber
          phoneNumber
        }
        notifySameAsTradePartner

        # Disable apollo caching for that object
        __typename @skip(if: true)
      }
      masterBlIssueDate
      masterBlIssuePlace
      masterBlNumber
      masterBlShipper {
        address
        companyName
        contactName
        email
        faxNumber
        phoneNumber
      }
      overseasForwarder
      podName
      polName
      tradeDirection
      truckingDomesticRequested
      truckingOverseasRequested
      responsibilityType
    }
  }
`;
