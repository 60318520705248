import { gql } from '@apollo/client';

export default gql`
  query GetCompanyCustomerTeams($companyId: ID, $pagination: PaginationInput, $serviceType: Int){
    customerTeamSearch(companyId: $companyId, pagination: $pagination, serviceType: $serviceType) {
      id
      name
    }
  }
`;
