import { gql } from '@apollo/client';

export default gql`
  query DataCleansingReasonList(
    $sctType: SctTypeEnum
  ) {
    dataCleansingReasonList(sctType: $sctType) {
      id
      enabled
      name
      sctType
      description
      attachments {
        id
        name
        url
        uploadedAt
      }
    }
  }
`;
