import React from 'react';
import { Route, Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';

import { getUserTokenFromCookie } from '../../config/cookies';

/**
 * AuthRoute component checks the Authentication before route navigation.
 * This check only happens on app start and is only done once.
 */
const AuthRoute = (props) => {
  if (!getUserTokenFromCookie()) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
};

export default withRouter(AuthRoute);
