import { gql } from '@apollo/client';

export default gql`
  mutation AssignToUserGroup(
    $userGroupId: ID!
    $customerUserIds: [ID!]
  ) {
    assignToUserGroup(
      userGroupId: $userGroupId
      customerUserIds: $customerUserIds
    ) {
      id
    }
  }
`;
