import { gql } from '@apollo/client';

export default gql`
  mutation UpsertDraftBillItems(
    $shipmentId: ID!
    $billItems: [DraftBillItemInput!]!
  ) {
    upsertDraftBillItems(shipmentId: $shipmentId, billItems: $billItems) {
      id
    }
  }
`;
