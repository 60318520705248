import { gql } from '@apollo/client';

export default gql`
  mutation updateDataCleansingReason($id: ID!, $dataCleansingReasonInput: DataCleansingReasonInput!) {
    updateDataCleansingReason(
      dataCleansingReasonInput: $dataCleansingReasonInput
      id: $id
    ) {
      id
    }
  }
`;
