import { gql } from '@apollo/client';

export default gql`
  query GetCustomerTeamPartnerContract($customerTeamId: ID) {
    customerTeamSearch(customerTeamId: $customerTeamId) {
      partners {
        id
        name
        company {
          id
          name
        }
      }
    }
  }
`;
