import { gql } from '@apollo/client';

export default gql`
  fragment BasicFields on Shipment {
    id
    customerReference
    cargo {
      id
      cargoType
    }
    incoterm
    internalReference
    responsibilityType
  }
`;
