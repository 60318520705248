import { gql } from '@apollo/client';

export default gql`
  query staffList($staffId: ID, $searchString: String, $filter: StaffFilterEnum, $pagination: PaginationInput) {
    staffSearch(staffId: $staffId, searchString: $searchString, filter: $filter, pagination: $pagination) {
      id
      name
    }
  }
`;
