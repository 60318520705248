import { gql } from '@apollo/client';

export default gql`
query CustomerPartnerAddresses($customerTeamId: ID!, $tradePartnerId: ID) {
  addressSearch(
    customerTeamId: $customerTeamId
    tradePartnerId: $tradePartnerId
  ) {
    id
    name
    isShared
  }
}
`;
