import { gql } from '@apollo/client';

export default gql`
  mutation uploadAttachment(
    $relatedObject: RelatedObjectInput!
    $attachmentArgs: RemoteDirectUploadLinkInput!
    $icpId: ID
  ) {
    uploadAttachment(
      relatedObject: $relatedObject
      attachmentArgs: $attachmentArgs
      icpId: $icpId
    ) {
      id
      name
      url
    }
  }
`;
